import { AwsRum, AwsRumConfig } from 'aws-rum-web';

let awsRum: AwsRum | null = null

export const getAwsRum = (): AwsRum | null => {
    return awsRum
}


export const configureAwsRum = () => {
    try {
        const config: AwsRumConfig = {
            sessionSampleRate: 1,
            guestRoleArn: "arn:aws:iam::888687353894:role/RUM-Monitor-us-east-1-888687353894-9259174050961-Unauth",
            identityPoolId: "us-east-1:af9bee0f-ef71-42a9-989a-8066f5e4a98a",
            endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
            telemetries: ["performance", "errors", "http"],
            allowCookies: true,
            enableXRay: false
        };

        const APPLICATION_ID: string = '5a79aa5a-1d7f-464d-baaa-501e92779cb7';
        const APPLICATION_VERSION: string = '1.0.0';
        const APPLICATION_REGION: string = 'us-east-1';

        awsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
        );
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
}