import ResumeApp from './Resume/App'


function App() {
  return (
    <ResumeApp />
  );
}

export default App;
